import { Carousel, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Button from '../common/Button';
import useGlobalStore from '../../stores/useGlobalStore';
import { CAROUSEL_ITEMS } from './constants';

function HowItWorksModal({ isModalOpen, setIsModalOpen }) {
  const carouselRef = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const profileData = useGlobalStore((state) => state.profileData);
  const [carouselItems, setCarouselItems] = useState([]);
  const [_isModalOpen, _setIsModalOpen] = useState(false);

  const handleNext = () => {
    if (carouselItems.length - 1 === currentSlideIndex) {
      setIsModalOpen(false);
      return _setIsModalOpen(false);
    }
    return carouselRef.current?.innerSlider.slickNext();
  };

  const buildCarouselItems = () => {
    if (profileData?.data.attributes.use_my_quiz_only || profileData?.data.attributes.is_joiner) {
      return CAROUSEL_ITEMS.slice(1);
    }
    return CAROUSEL_ITEMS;
  };

  useEffect(() => {
    if (profileData) {
      setCarouselItems(buildCarouselItems());
      setIsModalOpen(isModalOpen);
      _setIsModalOpen(isModalOpen);
    }
  }, [profileData]);

  return (
    <Modal
      width="41rem"
      open={_isModalOpen}
      onCancel={() => {
        setIsModalOpen(false);
        _setIsModalOpen(false);
      }}
      footer={null}
      closable={false}
      maskClosable={false}
      className="how-it-works"
      centered
    >
      <Carousel
        adaptiveHeight
        beforeChange={(_, next) => setCurrentSlideIndex(next)}
        ref={carouselRef}
        dotPosition="bottom"
        infinite={false}
      >
        {carouselItems.map((item) => (
          <div key={item.id}>
            <img src={item.image} alt="" className="rounded-t-xl object-cover h-72" />
            <div className="p-3.5 pb-0">
              <p className="text-custom-pink font-bold text-lg">HOW IT WORKS</p>
              <h2 className="my-2.5 font-bold text-2xl">{item.heading}</h2>
              <p className="text-2xl">{item.description}</p>
            </div>
          </div>
        ))}
      </Carousel>
      <div className="flex justify-end pb-3.5 mt-3.7">
        <Button
          secondary
          className="mr-7.5 z-50"
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </Modal>
  );
}

export default HowItWorksModal;
