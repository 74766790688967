import React from 'react';

import StepDashedLine from '../../../assets/step-dashed-line.svg';
import StepCompleted from '../../../assets/step-completed-icon.svg';
import StepInCompleted from '../../../assets/step-incomplete-icon.svg';

function Steps({ items }) {
  return (
    <div className="flex justify-center items-center flex-col">
      <div className="flex">
        {
          items.map((item, idx) => (
            <>
              <div className="relative">
                <img src={item.status === 'finish' ? StepCompleted : StepInCompleted} alt="" />
              </div>
              {idx !== (items.length - 1) && <img className="w-36 lg:w-auto" src={StepDashedLine} alt="" />}
            </>
          ))
        }
      </div>
      <div className="flex justify-between w-[111%] mt-[0.88rem]">
        {items.map((item) => (
          <span>{item.title}</span>
        ))}
      </div>
    </div>
  );
}

export default Steps;
