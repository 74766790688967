import { Modal } from 'antd';
import React from 'react';
import Button from '../../common/Button';

function NoSwapsLeftModal({
  isModalOpen, setIsModalOpen,
}) {
  return (
    <Modal
      width="41rem"
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
      maskClosable={false}
      closable={false}
      className="card-details"
      centered
    >
      <div className="p-3.5">
        <h3 className="text-pink-500 text-lg font-bold">
          NO SWAPS AVAILABLE
        </h3>
        <h2 className="font-bold text-2xl my-2.5">Unable to replace this date with a new one</h2>
        <p className="text-2xl">
          Sorry, you’ve used all your allotted swaps, or we don’t have any more dates that
          match you preferences from your Dream Date Quiz. If you’re still unhappy
          with the dates you have, email us at support@lovewick.com, and we’ll do our
          best to help!
        </p>

        <div className="flex justify-between mt-3.7">
          <Button secondary onClick={() => setIsModalOpen(false)}>Close</Button>
        </div>
      </div>
    </Modal>
  );
}

export default NoSwapsLeftModal;
