import { Modal } from 'antd';
import React from 'react';
import Clock from '../../assets/clock.svg';
import { splitLineBreaks } from '../../utils/general';
import VibeTags from '../VibeTags';

function SuppliesModal({ date, isModalOpen, setIsModalOpen }) {
  return (
    <Modal
      width="37rem"
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
      maskClosable={false}
      className="card-details"
      centered
    >
      <div className="w-[37.03rem]">
        <div className="w-full h-44">
          <img
            src={date?.attributes.review_pending_detail_image_url}
            alt=""
            className="object-cover w-full h-full rounded-t-lg"
          />
        </div>
        <div className="py-5 px-3.5">
          <div className="border-b border-[#D9D9D9] mb-3.7">
            <VibeTags date={date} />
            <h3 className=" text-3xl my-3.7 font-bold">{date?.attributes?.title}</h3>
            <div className="flex fold-bold mb-3.7 font-bold text-xl">
              <span className="mr-2.5">
                {date?.attributes?.activity_type}
                {' '}
                Date
              </span>
              <span className="flex items-center">
                <img src={Clock} alt="Clock" className="mr-2.5" />
                Min
                {' '}
                {date?.attributes?.estimated_duration}
              </span>
            </div>
          </div>
          <div className="border-b border-gray-300 mb-3.7">
            <h4 className="font-bold text-xl">What you need:</h4>
            <ul className="text-xl mb-3.7">
              {
                splitLineBreaks(date?.attributes?.what_you_need)?.map((text) => (
                  <li>
                    <span className="mx-2">•</span>
                    <span dangerouslySetInnerHTML={{ __html: text }} />
                  </li>
                ))
              }
            </ul>
          </div>
          <div className="mb-3.7">
            <h4 className="text-xl font-bold">Other recommendations:</h4>
            <ul className="text-xl">
              {
                splitLineBreaks(date?.attributes?.other_recommendations)?.map((text) => (
                  <li>
                    <span className="mx-2">•</span>
                    <span dangerouslySetInnerHTML={{ __html: text }} />
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default SuppliesModal;
