import { Modal } from 'antd';
import React from 'react';
import Button from '../../common/Button';
import useGlobalStore from '../../../stores/useGlobalStore';

function SwapDateModal({
  isSubmitting, isModalOpen, setIsModalOpen, onContinueWithSwapClick, onNevermindClick,
}) {
  const profileData = useGlobalStore((state) => state.profileData);

  return (
    <Modal
      width="41rem"
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
      maskClosable={false}
      closable={false}
      className="card-details"
      centered
    >
      <div className="p-3.5">
        <h3 className="text-pink-500 text-lg font-bold">
          YOU HAVE
          {' '}
          {profileData.data.attributes.total_dates_left_to_be_swapped_on_positive_likability}
          {' '}
          SWAPS LEFT
        </h3>
        <h2 className="font-bold text-2xl my-2.5">Replace this date with a new one?</h2>
        <p className="text-2xl">
          If a date on your dashboard looks like something you&apos;ve done recently or
          clearly aren&apos;t interested in,
          you can swap it out for a new date, on us!
        </p>

        <div className="flex justify-between mt-3.7">
          <Button
            disabled={isSubmitting}
            onClick={onContinueWithSwapClick}
            secondary
          >
            Continue with swap
          </Button>
          <Button onClick={onNevermindClick}>Nevermind</Button>
        </div>
      </div>
    </Modal>
  );
}

export default SwapDateModal;
