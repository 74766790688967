const routes = {
  root: '/',
  dates: {
    index: '/dates',
    show: '/dates/:id',
  },
  auth: {
    login: 'login',
  },
  paymentConfirmation: {
    index: '/payment-confirmation',
  },
  dashboard: {
    index: '/dashboard',
  },
  room: {
    ready: '/room/ready',
    show: '/room/:roomSlug',
  },
  tailoringQuiz: {
    index: '/tailoring-quiz',
  },
  tooManyAttempts: {
    index: 'toomany-attempts',
  },
  error: {
    index: '/error',
  },
};

export default routes;
