import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import './stylesheets/main.scss';
import { ToastContainer } from 'react-toastify';
import { isMobile } from './utils/general';
import MobileNotSupported from './components/MobileNotSupported';
import { LOCALSTORAGE_KEYS, MOBILE_NOT_SUPPORTED_IGNORED_ROUTES } from './constants';
import useQuery from './hooks/useQuery';
import routes from './routes';
import useGlobalStore from './stores/useGlobalStore';
import 'react-toastify/dist/ReactToastify.css';

function Root() {
  const location = useLocation();
  const currentPath = location.pathname;
  const query = useQuery();
  const navigate = useNavigate();
  const setGlobalState = useGlobalStore((state) => state.setGlobalState);

  useEffect(() => {
    // If magic link was clicked:
    const authEmail = query.get('email')?.replace(' ', '+');
    const authToken = query.get('authentication_token');
    if (authToken && authEmail && query.get('date') === 'true') {
      localStorage.setItem('auth_token', authToken);
      localStorage.setItem(LOCALSTORAGE_KEYS.USER_EMAIL, authEmail);
      setGlobalState({ authEmail, authToken });
      navigate(routes.dashboard.index);
    }
  }, []);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('auth_token') && localStorage.getItem(LOCALSTORAGE_KEYS.USER_EMAIL);
    const isInLandingPage = currentPath === routes.root;
    if (isLoggedIn && isInLandingPage) {
      navigate(routes.dashboard.index);
    }
  }, []);

  if (isMobile() && !MOBILE_NOT_SUPPORTED_IGNORED_ROUTES.includes(currentPath)) {
    return <MobileNotSupported />;
  }

  return (
    <>
      <Outlet />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default Root;
