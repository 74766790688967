/* eslint-disable import/prefer-default-export */

export const QUESTION_TYPES = {
  RadioQuestion: 'RadioQuestion',
  TextQuestion: 'TextQuestion',
  ThreeScaleQuestion: 'ThreeScaleQuestion',
  CheckboxQuestion: 'CheckboxQuestion',
};

export const VALIDATION_MSGS = {
  FIELD_IS_REQUIRED: 'Field is required',
  SELECT_AN_OPTION: 'Please select an option',
  SELECT_AT_LEAST_ONE_OPTION: 'Please select at least one option',
};

export const SEND_INVITE_IDENTIFIER = 'send_partner_invite';
