import React from 'react';
import {
  Formik, Form, Field,
} from 'formik';
import LogoWhite from '../../assets/logo-white.svg';
import Card from '../common/Card';
import Button from '../common/Button';
import Input from '../common/Input';
import auth from '../../apis/auth';
import { LOGIN_SCHEMA } from './constants';
import { toastSuccess } from '../../utils/general';

function Login() {
  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    toastSuccess('Magic link sent!');
    await auth.requestMagicLink(values.email);
    setSubmitting(false);
  };

  return (
    <div className="cyan-gradient-bg-image w-full h-full mx-auto">
      <div className="container mx-auto pt-28 items-center flex-col flex h-full">
        <img src={LogoWhite} alt="Love wick logo" className="w-28 h-28 mb-8" />
        <Card className="py-10">
          <h1 className="text-center">
            Welcome back!
          </h1>
          <p className="text-center mt-2.5">
            Enter your email for a magic link to
            access your dashboard
          </p>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={LOGIN_SCHEMA}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="flex flex-col items-center">
                <Field className="w-full my-7.5" type="email" name="email" component={Input} placeholder="Enter your email" />
                <Button secondary type="submit" disabled={isSubmitting} className="w-full mx-auto">Request magic link</Button>
              </Form>
            )}
          </Formik>
        </Card>
      </div>
    </div>
  );
}

export default Login;
