import { Modal } from 'antd';
import React, { useState } from 'react';
import Button from '../common/Button';
import { SUPPORT_EMAIL } from './constants';

function ContactSupportModal({ isModalOpen, setIsModalOpen }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCopyEmail = async () => {
    setIsSubmitting(true);
    await navigator.clipboard.writeText(SUPPORT_EMAIL);
    setIsSubmitting(false);
    setIsModalOpen(false);
  };

  return (
    <Modal
      width="41rem"
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
      maskClosable={false}
      closable={false}
      className="profile-modal"
      centered
    >
      <div className="p-3.5">
        <h3 className="text-pink-500 text-lg font-bold">CONTACT SUPPORT</h3>
        <h2 className="font-bold text-2xl my-2.5">
          Email:
          {' '}
          {SUPPORT_EMAIL}
        </h2>
        <p className="text-2xl">
          Please send your message from the email tied to your account and attach any relevant
          screenshots or videos.
          This will really speed up our small team’s ability to help.
          Thanks in advance for your patience!
        </p>

        <div className="flex justify-between mt-3.7">
          <Button
            disabled={isSubmitting}
            secondary
            onClick={handleCopyEmail}
          >
            Copy email address
          </Button>
          <Button onClick={() => setIsModalOpen(false)}>Nevermind</Button>
        </div>
      </div>
    </Modal>
  );
}

export default ContactSupportModal;
