import React from 'react';

function CustomStar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="37"
      viewBox="0 0 40 37"
      fill="currentColor"
    >
      <path
        d="M13.7638 13.1117L13.6962 12.382L13.7638 13.1117C14.298 13.0621 14.7623 12.7247 14.9745 12.2321L19.875 0.852613L24.7755 12.2321C24.9877 12.7247 25.4521 13.0621 25.9862 13.1117L38.323 14.2559L29.0148 22.433C28.6119 22.787 28.4345 23.3329 28.5524 23.8562L31.2765 35.9428L20.6232 29.6171C20.162 29.3432 19.588 29.3432 19.1268 29.6171L8.4735 35.9428L11.1976 23.8562C11.3155 23.3329 11.1381 22.787 10.7352 22.433L1.42699 14.2559L13.7638 13.1117Z"
        stroke="black"
        strokeWidth="1.46552"
      />
    </svg>
  );
}

export default CustomStar;
