import React from 'react';

function Card({ children }) {
  return (
    <div className="mt-10 p-13 lg:p-13 w-full flex flex-col border border-gray-200 rounded-2.5xl shadow-custom-card-light bg-white mb-10">
      {children}
    </div>
  );
}

export default Card;
