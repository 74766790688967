/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Radio } from 'antd';
import {
  Field,
} from 'formik';
import ValidationError from '../../common/ValidationError';
import { validateRequired } from '../utils';
import { VALIDATION_MSGS } from '../constants';

function ScaledSingleSelect({
  name, options,
}) {
  return (
    <div className="flex flex-col gap-3 w-full">
      <Field
        validate={validateRequired(VALIDATION_MSGS.SELECT_AN_OPTION)}
        name={name}
        render={({ field }) => (
          <div>
            <Radio.Group {...field}>
              {options.map((option) => (
                <Radio.Button value={option.id}>{option.name}</Radio.Button>
              ))}
            </Radio.Group>
            <ValidationError fieldName={field.name} />
          </div>
        )}
      />

    </div>
  );
}

export default ScaledSingleSelect;
