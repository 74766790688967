/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import {
  Field,
} from 'formik';
import classNames from 'classnames';
import ValidationError from '../../common/ValidationError';
import { validateRequired } from '../utils';
import { VALIDATION_MSGS } from '../constants';

function MultiSelect({ name, options }) {
  const [selectedIds, setSelectedIds] = useState([]);

  const onLabelClick = (e) => {
    const selectedId = e.target.value;

    if (selectedIds.includes(selectedId)) {
      setSelectedIds(selectedIds.filter((id) => id !== selectedId));
    } else {
      setSelectedIds([...selectedIds, selectedId]);
    }
  };

  return (
    <div className="flex flex-col gap-3 w-full multi-select">
      {options.map((item) => (
        <label
          key={item.id}
          className={classNames('transition-all cursor-pointer flex p-5 border border-black border-opacity-50 rounded-[0.625rem] select-none', {
            active: selectedIds.includes((item.id).toString()),
          })}
        >
          <Field validate={validateRequired(VALIDATION_MSGS.SELECT_AT_LEAST_ONE_OPTION)} onClick={onLabelClick} type="checkbox" name={name} value={(item.id).toString()} className="mr-3 w-5 accent-slate-900 cursor-pointer" />
          <p className="text-lg">{item.name}</p>
        </label>
      ))}
      <ValidationError fieldName={name} />
    </div>
  );
}

export default MultiSelect;
