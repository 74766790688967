/* eslint-disable no-unreachable */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Progress } from 'antd';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Card from './Card';
import LogoBlack from '../../assets/logo-black.svg';
import Button from '../common/Button';
import Spinner from '../common/Spinner';
import MultipleInput from './Types/MultipleInput';
import ScaledSingleSelect from './Types/ScaledSingleSelect';
import SingleSelect from './Types/SingleSelect';
import MultiSelect from './Types/MultiSelect';
import QuizApi from '../../apis/quizzes';
import {
  buildQuestionAnswers, convertToOptions, toAntdProgress,
} from './utils';
import { QUESTION_TYPES, SEND_INVITE_IDENTIFIER } from './constants';
import routes from '../../routes';
import useGlobalStore from '../../stores/useGlobalStore';
import AuthAPI from '../../apis/auth';
import { QUIZ_IDENTIFIERS } from '../../constants';
import Analytics, { EVENT_NAMES } from '../../utils/analytics';

// TODO: take the last one from the response not first.
function TailoringQuiz() {
  const [percentage, setPercentage] = useState(0);
  const [questionData, setQuestionData] = useState(null);
  const [quizData, setQuizData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const navigate = useNavigate();
  const setGlobalState = useGlobalStore((state) => state.setGlobalState);
  const profileData = useGlobalStore((state) => state.profileData);

  const generateInitialValues = () => {
    const callUsNameFirstOption = questionData?.attributes.answer_options.data.find(
      (option) => option.attributes.identifier === QUIZ_IDENTIFIERS.call_us_name_first_answer,
    );
    const callUsNameSecondOption = questionData?.attributes.answer_options.data.find(
      (option) => option.attributes.identifier === QUIZ_IDENTIFIERS.call_us_name_second_answer,
    );
    const partnersEmailOption = questionData?.attributes.answer_options.data.find(
      (option) => option.attributes.identifier === QUIZ_IDENTIFIERS.partners_email_answer,
    );
    const callMeNameOption = questionData?.attributes.answer_options.data.find(
      (option) => option.attributes.identifier === QUIZ_IDENTIFIERS.call_me_name_answer,
    );

    setInitialValues({
      ...(callUsNameFirstOption ? { [callUsNameFirstOption.id]: profileData?.data.attributes.name } : {}),
      ...(callUsNameSecondOption ? { [callUsNameSecondOption.id]: profileData?.data.attributes.second_user_name } : {}),
      ...(callMeNameOption ? { [callMeNameOption.id]: profileData?.data.attributes.name } : {}),
      ...(partnersEmailOption ? { [partnersEmailOption.id]: profileData?.data.attributes.joiner_email } : {}),
    });
  };

  const fetchProfile = async () => {
    const userData = await AuthAPI.getProfile();
    setGlobalState({ profileData: userData.data });
    return userData;
  };

  useEffect(() => {
    const fetch = async () => {
      const userData = await fetchProfile();
      const response = await QuizApi.getQuiz();
      if (
        (userData.data.data.attributes.is_purchaser && response.data.data.attributes.purchaser_progress > 1)
        || (userData.data.data.attributes.is_joiner && response.data.data.attributes.joiner_progress > 1)
      ) {
        navigate(routes.dashboard.index);
      }
      setIsLoading(false);
      setQuizData(response.data.data);
      setQuestionData(response.data.data.attributes.current_question.data);
      if (userData.data.data.attributes.is_purchaser) {
        setPercentage(toAntdProgress(response.data.data.attributes.purchaser_progress));
      } else {
        setPercentage(toAntdProgress(response.data.data.attributes.joiner_progress));
      }
      Analytics.track(
        EVENT_NAMES.QUIZ_VIEW_QUIZ_PAGE,
        Analytics.buildViewQuizPageProperties(
          response.data.data.attributes.current_question.data.attributes.identifier,
        ),
      );
    };
    fetch();
  }, []);

  useEffect(() => {
    if (questionData && profileData) {
      generateInitialValues();
    }
  }, [questionData, profileData]);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    setIsLoading(true);
    const answers = buildQuestionAnswers(questionData, values);
    const response = await QuizApi.submitAnswer({ quizId: quizData.id, questionId: questionData.id, answers });
    setQuestionData(response.data.data[0].attributes.current_question.data);
    const userData = await fetchProfile();
    if (userData.data.data.attributes.is_purchaser) {
      setPercentage(toAntdProgress(response.data.data[0].attributes.purchaser_progress));
    } else {
      setPercentage(toAntdProgress(response.data.data[0].attributes.joiner_progress));
    }
    setIsLoading(false);
    setSubmitting(false);
    if (percentage === 100) {
      setGlobalState({ showHowItWorks: true });
      navigate(routes.dashboard.index);
    }

    Analytics.track(
      EVENT_NAMES.QUIZ_SUBMIT_QUIZ_ANSWER,
      Analytics.buildQuizSubmitAnswerProperties(questionData, answers),
    );
    Analytics.track(
      EVENT_NAMES.QUIZ_VIEW_QUIZ_PAGE,
      Analytics.buildViewQuizPageProperties(
        response.data.data[0].attributes.current_question.data.attributes.identifier,
      ),
    );
  };

  const handleFinalSubmit = async ({ values, validateForm, sendInvite }) => {
    const errors = await validateForm();
    if (!isEmpty(errors)) return;

    setIsLoading(true);
    await QuizApi.submitAnswer({
      quizId: quizData.id,
      questionId: questionData.id,
      answers: [{
        answer_option_id: Number(Object.keys(values)[0]),
        value: Object.values(values)[0],
      }],
    });
    await fetchProfile();
    await QuizApi.sendPartnerInvite({ quizId: quizData.id, sendInvite });
    setIsLoading(false);
    setGlobalState({ showHowItWorks: true });
    navigate(routes.dashboard.index);
    Analytics.track(sendInvite ? EVENT_NAMES.QUIZ_SEND_PARTNER_INVITE : EVENT_NAMES.QUIZ_SKIP_PARTNER_INVITE);
  };

  return (
    <div className="w-full min-h-full mx-auto bg-gray-50 tailoring-quiz-page">
      <div className="container mx-auto pt-10 justify-center items-center flex-col flex h-full md:max-w-[47rem] xl:max-w-[63rem]">
        <Spinner loading={isLoading} className="!absolute !top-1/2 !left-1/2">
          <img src={LogoBlack} alt="Love wick logo" className="w-28 h-28" />
          <h3 className="text-[2.12rem] font-bold my-7.5">
            Dream Date Quiz
          </h3>
          <div className="quiz-header w-full flex px-1">
            <Progress percent={percentage} showInfo={false} className="" />
          </div>
          <Card className="mt-10">
            <h3 className="text-3.5xl font-bold">
              {questionData?.attributes.title}
            </h3>
            <p className="text-lg mb-7.5 mt-5">{questionData?.attributes.sub_title}</p>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({ isSubmitting, values, validateForm }) => (
                <Form className="flex flex-col">
                  {questionData?.attributes.type === QUESTION_TYPES.RadioQuestion && (
                    <SingleSelect
                      name={questionData?.id}
                      options={convertToOptions(questionData?.attributes.answer_options.data)}
                    />
                  )}
                  {questionData?.attributes.type === QUESTION_TYPES.TextQuestion && (
                    <MultipleInput
                      questionData={questionData}
                      options={convertToOptions(questionData?.attributes.answer_options.data)}
                    />
                  )}
                  {questionData?.attributes.type === QUESTION_TYPES.ThreeScaleQuestion && (
                    <ScaledSingleSelect
                      name={questionData?.id}
                      options={convertToOptions(questionData?.attributes.answer_options.data)}
                    />
                  )}
                  {questionData?.attributes.type === QUESTION_TYPES.CheckboxQuestion && (
                    <MultiSelect
                      name={questionData?.id}
                      options={convertToOptions(questionData?.attributes.answer_options.data)}
                    />
                  )}
                  {(percentage === 100 && questionData.attributes.identifier === SEND_INVITE_IDENTIFIER)
                    ? (
                      <div className="flex mt-7.5 flex-wrap">
                        <Button onClick={() => handleFinalSubmit({ values, validateForm, sendInvite: true })} className="w-fit py-3.5 px-7 mr-4" disabled={isSubmitting} secondary>Send invite now</Button>
                        <Button onClick={() => handleFinalSubmit({ values, validateForm, sendInvite: false })} className="w-fit py-3.5 px-7 mt-3 md:mt-0" disabled={isSubmitting}>Send invite later</Button>
                      </div>
                    ) : (
                      <Button
                        type="submit"
                        className="w-fit py-3.5 px-7 mt-7.5"
                        disabled={isSubmitting}
                        secondary
                      >
                        Continue
                      </Button>
                    )}
                </Form>
              )}
            </Formik>
          </Card>
        </Spinner>
      </div>
    </div>
  );
}

export default TailoringQuiz;
