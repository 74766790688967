import useGlobalStore from '../stores/useGlobalStore';
import Analytics from '../utils/analytics';

function useAuth() {
  const isAuthenticated = localStorage.getItem('user_email') && localStorage.getItem('auth_token');
  const setGlobalState = useGlobalStore((state) => state.setGlobalState);

  const logout = () => {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('user_email');
    setGlobalState({ authToken: null, authEmail: null });
    Analytics.reset();
  };

  return { isAuthenticated, logout };
}

export default useAuth;
