import { create } from 'zustand';

const useGlobalStore = create((set) => ({
  // authToken: localStorage.getItem('auth_token') || 'zLy1zEgZ9Tqs5Ynpenjg',
  // authEmail: localStorage.getItem('email') || 'adil.ismail@bigbinary.com',
  authToken: localStorage.getItem('auth_token'),
  authEmail: localStorage.getItem('user_email'),
  showHowItWorks: false,
  beforeJoiningModalShown: !!localStorage.getItem('before_joining_modal_shown'),
  profileData: null,
  roomSlug: localStorage.getItem('room_slug'),

  setGlobalState: set,
}));

export default useGlobalStore;
