/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import Button from '../../common/Button';
import { OVERLAY_LINEAR_GRADIENT } from '../../../constants';

function ReviewSubmittedCard({
  tileImageUrl,
  date,
  onDetailsClick,
}) {
  return (
    <div className="shadow-custom-card-light card w-[22rem] lg:w-[24.3rem] border border-black border-opacity-20 bg-white rounded-2.5xl">
      <div className="relative">
        <div className="relative">
          <div
            className="inset-0"
            style={{ background: OVERLAY_LINEAR_GRADIENT }}
          />
          <img src={tileImageUrl} alt="Background" className="rounded-t-[1.20rem] w-full  h-[17.6rem] object-cover" />
        </div>
      </div>
      <div className="flex flex-col gap-y-3.7 p-3.5 pb-[1.07rem] items-center">
        <h3 className="text-3xl font-bold">
          ✔️
          {' '}
          {date.attributes.title}
        </h3>
        <div>
          <Button
            onClick={() => onDetailsClick(date)}
          >
            Details
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ReviewSubmittedCard;
