/* eslint-disable no-param-reassign */
import axios from 'axios';
import useGlobalStore from '../stores/useGlobalStore';

axios.defaults.headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

axios.interceptors.response.use(
  (res) => res,
  (error) => {
    console.log(error);
  },
);

axios.interceptors.request.use(
  (config) => {
    const { authToken, authEmail } = useGlobalStore.getState();
    config.headers['X-Auth-Email'] = authEmail;
    config.headers['X-Auth-Token'] = authToken;

    config.params = { ...config.params, date: 'true' };

    return config;
  },
  (error) => {
    console.error('Axios Request Error:', error);
    return Promise.reject(error);
  },
);

export default axios;
