/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import WaitingForPartner from './WaitingForPartner';
import DatesList from './DatesList';
import useGlobalStore from '../../stores/useGlobalStore';
import HowItWorksModal from './HowItWorksModal';
import AuthAPI from '../../apis/auth';
import routes from '../../routes';
import Spinner from '../common/Spinner';

function Dashboard() {
  const navigate = useNavigate();
  const showHowItWorks = useGlobalStore((state) => state.showHowItWorks);
  const setGlobalState = useGlobalStore((state) => state.setGlobalState);
  const profileData = useGlobalStore((state) => state.profileData);

  const fetchProfile = async () => {
    const resp = await AuthAPI.getProfile();
    setGlobalState({ profileData: resp.data });
  };

  useEffect(() => {
    if (profileData === null) {
      fetchProfile();
    }
  }, [profileData]);

  useEffect(() => {
    fetchProfile();
  }, []);

  const hasPurchaserCompletedQuiz = profileData?.data.attributes.purchaser_progress > 1.0;
  const hasJoinerCompletedQuiz = profileData?.data.attributes.joiner_progress >= 1.0;
  const isUseMyQuizOnly = profileData?.data.attributes.use_my_quiz_only;

  if (profileData?.data.attributes.is_purchaser && !hasPurchaserCompletedQuiz) {
    return navigate(routes.tailoringQuiz.index);
  }
  if (profileData?.data.attributes.is_joiner && !hasJoinerCompletedQuiz) {
    return navigate(routes.tailoringQuiz.index);
  }

  return (
    <Spinner loading={!profileData} className="!absolute !top-1/2 !left-1/2">
      {(isUseMyQuizOnly || hasJoinerCompletedQuiz) ? <DatesList /> : <WaitingForPartner />}
      <HowItWorksModal
        isModalOpen={showHowItWorks}
        setIsModalOpen={(isOpen) => {
          setGlobalState({ showHowItWorks: isOpen });
        }}
      />
    </Spinner>
  );
}

export default Dashboard;
