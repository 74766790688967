import React, { useEffect, useState } from 'react';
import Layout from '../Layout';
import WaitingGif from '../../../assets/waiting-gif.gif';
import StepCompletedIcon from '../../../assets/step-completed-icon.svg';
import StepInCompleteIcon from '../../../assets/step-incomplete-icon.svg';
import Steps from './Steps';
import Button from '../../common/Button';
import SendPartnerInvite from './SendPartnerInvite';
import useGlobalStore from '../../../stores/useGlobalStore';
import QuizAPI from '../../../apis/quizzes';
import Spinner from '../../common/Spinner';
import { extractObjectByIdentifier } from '../DatesList/utils';
import { QUIZ_IDENTIFIERS } from '../../../constants';
import AuthAPI from '../../../apis/auth';
import Analytics, { EVENT_NAMES } from '../../../utils/analytics';

function WaitingForPartner() {
  const profileData = useGlobalStore((state) => state.profileData);
  const setGlobalState = useGlobalStore((state) => state.setGlobalState);
  const [isSendPartnerInviteModalOpen, setIsSendPartnerInviteModalOpen] = useState(false);
  const [quizData, setQuizData] = useState(null);
  const [isSubmitting, setIsSubmiting] = useState(false);
  const partnerName = profileData?.data.attributes.joiner_quiz_name || 'Partner';

  const fetchProfile = async () => {
    const userData = await AuthAPI.getProfile();
    setGlobalState({ profileData: userData.data });
  };

  useEffect(() => {
    const fetchQuiz = async () => {
      const response = await QuizAPI.getQuiz();
      setQuizData(response.data.data);
    };
    fetchQuiz();
  }, []);

  useEffect(() => {
    Analytics.track(EVENT_NAMES.DASHBOARD_VIEW_WAITING_STATE_PAGE);
  }, []);

  const handleSendPartnerInvite = () => {
    setIsSendPartnerInviteModalOpen(true);
  };

  const handleUseMyQuiz = async () => {
    Analytics.track(EVENT_NAMES.DASHBOARD_CLICK_USEMY_RESULTS_ONLY);
    setIsSubmiting(true);
    const question = extractObjectByIdentifier(quizData, QUIZ_IDENTIFIERS.use_how);
    const answers = [
      {
        answer_option_id: Number(
          question.attributes.answer_options.data[
            question.attributes.answer_options.data.length - 1
          ].id,
        ),
        value: '',
      },
    ];
    await QuizAPI.submitAnswer({
      quizId: quizData.id, questionId: question.id, answers, notQuiz: true,
    });
    await fetchProfile();
    setIsSubmiting(false);
    // TODO: change
    window.location.reload();
  };

  const handleSendInvite = async (values, { resetForm }) => {
    resetForm();
    const joinerEmail = values.email;
    const question = extractObjectByIdentifier(quizData, QUIZ_IDENTIFIERS.send_partner_invite);
    const answers = [
      {
        answer_option_id: Number(question.attributes.answer_options.data[0].id),
        value: values.email,
      },
    ];
    await QuizAPI.submitAnswer({
      quizId: quizData.id, questionId: question.id, answers, notQuiz: true,
    });
    await QuizAPI.sendPartnerInvite({ quizId: quizData.id, sendInvite: true, joinerEmail });
    setIsSendPartnerInviteModalOpen(false);
    Analytics.track(EVENT_NAMES.DASHBOARD_CLICK_SEND_PARTNER_INVITE);
  };

  return (
    <Layout wrapperClassNames="min-h-screen">
      <Spinner loading={!quizData} className="!absolute !top-1/2 !left-1/2">
        <div className="bg-white rounded-[1.25rem] mt-10 shadow-custom-card-light">
          <div className="flex items-center flex-col py-5 px-5 md:px-0 md:py-14.5">
            <SendPartnerInvite
              isModalOpen={isSendPartnerInviteModalOpen}
              setIsModalOpen={setIsSendPartnerInviteModalOpen}
              onSendInvite={handleSendInvite}
            />
            <img src={WaitingGif} alt="Waiting gif" className=" rounded-sm" />
            <h1 className="mt-10 text-2.5xl text-center">Your mystery date plan is in the works!</h1>
            <p className="mt-2.5 text-lg">
              It will be revealed when
              {' '}
              {partnerName}
              {' '}
              submits their quiz
            </p>
            <div className="mt-10">
              <Steps
                items={[
                  {
                    title: 'You take the quiz',
                    status: 'finish',
                    icon: <StepCompletedIcon />,
                  },
                  {
                    title: `${partnerName} takes the quiz`,
                    icon: <StepInCompleteIcon />,
                  },
                  {
                    title: 'Reveal your dates',
                    icon: <StepInCompleteIcon />,
                  },
                ]}
              />
            </div>
            <div className="flex mt-10 flex-col md:flex-row gap-3 md:gap-0">
              <Button secondary onClick={handleSendPartnerInvite} className=" mr-2 md:mr-4.3"> Send partner invite</Button>
              <Button disabled={isSubmitting} onClick={handleUseMyQuiz}>Use my quiz only</Button>
            </div>
          </div>
        </div>
      </Spinner>
    </Layout>
  );
}

export default WaitingForPartner;
