/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Modal, Rate, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { Field, Form, Formik } from 'formik';
import TextArea from '../../common/Textarea';
import Button from '../../common/Button';
import CustomStar from '../../CustomStar';
import ValidationError from '../../common/ValidationError';
import { REVIEW_FORM_SCHEMA } from './constants';

function ReviewModal({
  date, isModalOpen, setIsModalOpen, onSubmitReviewClick,
}) {
  const [fileList, setFileList] = useState([]);

  const beforeUpload = (file, setFieldValue) => {
    setFieldValue('imageName', file.name);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFileList((prev) => [...prev, { url: reader.result }]);
      setFieldValue('image', reader.result);
    };

    return false;
  };

  const onRemove = (setFieldValue) => {
    setFieldValue('image', '');
    setFieldValue('imageName', '');
    setFileList([]);
  };

  useEffect(() => {
    if (date?.attributes.relationships_dream_date.data.attributes.review_image_url) {
      setFileList([{
        uid: '0',
        name: 'review_image.png',
        status: 'done',
        url: date?.attributes.relationships_dream_date.data.attributes.review_image_url,
        thumbUrl: date?.attributes.relationships_dream_date.data.attributes.review_image_url,
      }]);
    } else {
      setFileList([]);
    }
  }, [date]);

  return (
    <Modal
      width="32.68rem"
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
      closable={false}
      className="review-modal"
      centered
    >
      <Formik
        enableReinitialize
        initialValues={{
          rating: Number(date?.attributes.relationships_dream_date.data.attributes.review_stars),
          feedback: date?.attributes.relationships_dream_date.data.attributes.review_text,
          image: '',
          imageName: '',
        }}
        validationSchema={REVIEW_FORM_SCHEMA}
        onSubmit={onSubmitReviewClick}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form className="">
            <div className="flex flex-col items-center px-10 py-13">
              <h3 className="text-3.7xl font-bold">How did your date go?</h3>
              <Field name="rating">
                {({ field }) => (
                  <>
                    <Rate
                      allowHalf
                      character={<CustomStar />}
                      style={{ fontSize: 30 }}
                      className="my-5 review-rate"
                      onChange={(value) => setFieldValue('rating', value)}
                      value={field.value}
                    />
                    <ValidationError className="text-xl mx-auto mb-5" fieldName={field.name} />
                  </>
                )}
              </Field>
              <Field name="feedback">
                {({ field }) => (
                  <TextArea
                    {...field}
                    className="w-full max-w-[26.5rem]"
                    placeholder="Leave feedback for the Lovewick team, good or bad!"
                  />
                )}
              </Field>
              <div className="flex w-full max-w-[26.5rem] h-[19.8rem] justify-center items-center gap-1/16 flex-shrink-0 rounded-lg border border-dashed border-black mt-5 text-xl p-2">
                <Field name="image">
                  {() => (
                    <ImgCrop aspect={1.34}>
                      <Upload
                        customRequest={({ onSuccess }) => onSuccess('ok')}
                        listType="picture-card"
                        fileList={fileList}
                        onRemove={() => onRemove(setFieldValue)}
                        beforeUpload={(file) => beforeUpload(file, setFieldValue)}
                      >
                        {fileList.length < 1
                        && (
                          <>📸 Upload a private photo/screenshot for your memories (.jpeg or .png)</>
                        )}
                      </Upload>
                    </ImgCrop>
                  )}
                </Field>
              </div>
              <Button disabled={isSubmitting} type="submit" secondary className="mt-7.5 w-full max-w-[27.25rem]">Submit review</Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ReviewModal;
