/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import React from 'react';

function Button({ children, ...otherProps }) {
  const styles = {
    common: 'transition-all select-none px-3.5 py-3.7 text-lg font-bold rounded-[5rem] disabled:cursor-not-allowed',
    secondary: 'outline-[3px] border-custom-gray bg-custom-gray text-white disabled:text-white-opacity-50 hover:shadow-custom-button active:bg-black',
    default: 'border border-black bg-white text-black disabled:text-black-opacity-50 active:outline-custom-gray active:outline active:outline-[3px] hover:shadow-custom-button focus-visible:outline-black',
  };

  return (
    <button
      type="button"
      {...otherProps}
      className={classNames(
        styles.common,
        otherProps.className,
        {
          [styles.default]: !otherProps.secondary,
          [styles.secondary]: otherProps.secondary,
        },
      )}
    >
      {children}
    </button>
  );
}

export default Button;
