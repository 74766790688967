/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Radio } from 'antd';
import { Field } from 'formik';
import ValidationError from '../../common/ValidationError';
import { validateRequired } from '../utils';
import { VALIDATION_MSGS } from '../constants';

function SingleSelect({ name, options }) {
  return (
    <div className="flex flex-col">
      <Field
        name={name}
        validate={validateRequired(VALIDATION_MSGS.SELECT_AN_OPTION)}
        render={({ field }) => (
          <Radio.Group {...field} className="flex flex-col">
            {options.map((option) => (
              <Radio key={option.id} className="text-lg" value={option.id}>
                {option.name}
              </Radio>
            ))}
          </Radio.Group>
        )}
      />
      <ValidationError fieldName={name} />
    </div>
  );
}

export default SingleSelect;
