/* eslint-disable max-len */
import { V1_API_BASE_URL } from '../constants';
import useGlobalStore from '../stores/useGlobalStore';
import { getCurrentDate } from '../utils/general';
import axios from './axios';

const getDates = () => axios.get(`${V1_API_BASE_URL}/dream_dates`);

const startDate = (relationShipDreamDatesId) => {
  const { authToken, authEmail } = useGlobalStore.getState();

  return fetch(`${V1_API_BASE_URL}/relationships_dream_dates/${relationShipDreamDatesId}?date=true`, {
    method: 'PATCH',
    importance: 'high',
    headers: {
      'Content-Type': 'application/json',
      ...(authEmail && { 'X-Auth-Email': authEmail }),
      ...(authToken && { 'X-Auth-Token': authToken }),
    },
    keepalive: true,
    body: JSON.stringify({
      relationships_dream_date: {
        started_at: getCurrentDate(),
      },
    }),
  });
};

const submitReview = ({
  relationShipDreamDateId, reviewStars, reviewText, fileName, imageBase64, userId,
}) => axios.patch(`${V1_API_BASE_URL}/relationships_dream_dates/${relationShipDreamDateId}`, {
  relationships_dream_date: {
    ...(imageBase64 && { review_image: { file: imageBase64, name: fileName } }),
    ...(reviewStars && { review_stars: reviewStars }),
    ...(reviewText && { review_text: reviewText }),
    reviewed_at: getCurrentDate(),
    ...(userId && { review_user_id: userId }),
  },
});

const swapDate = (dreamDateId) => axios.patch(`${V1_API_BASE_URL}/relationships_dream_dates/${dreamDateId}/swap`);

export default {
  getDates,
  startDate,
  submitReview,
  swapDate,
};
