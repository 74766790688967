/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Input as AntdInput } from 'antd';
import classNames from 'classnames';
import ValidationError from './ValidationError';

function Input(
  {
    field,
    className,
    inputClassName,
    ...otherProps
  },
) {
  return (
    <div className={classNames('flex flex-col items-center justify-center transition-all', {
      [className]: !!className,
    })}
    >
      <AntdInput
        {...field}
        {...otherProps}
        className={classNames('rounded-sm border border-solid border-black py-3.7 px-5 focus:border-gray-950 focus:shadow-sm text-lg hover:border-black', {
          [inputClassName]: !!inputClassName,
        })}
      />
      <ValidationError fieldName={field.name} />
    </div>
  );
}

export default Input;
