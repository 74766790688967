import React from 'react';

function VibeTags({ date }) {
  return (
    <div className="flex gap-x-[0.62rem]">
      {date?.attributes?.tags.data.map((tag) => <span key={tag.id} className="rounded-full text-xl px-4 py-1.3" style={{ backgroundColor: tag.attributes.color_code }}>{tag.attributes.name}</span>)}
    </div>
  );
}

export default VibeTags;
