import classNames from 'classnames';
import React from 'react';

function Card({ children, className }) {
  return (
    <div className={classNames('p-7 md:p-13 flex flex-col border border-gray-200 max-w-[19rem] xxs:max-w-xs sm:max-w-md rounded-xl shadow-custom-card bg-white', {
      [className]: !!className,
    })}
    >
      {children}
    </div>
  );
}

export default Card;
