import React from 'react';
import { Field } from 'formik';
import Input from '../../common/Input';
import useGlobalStore from '../../../stores/useGlobalStore';
import { QUIZ_IDENTIFIERS } from '../../../constants';
import Analytics, { EVENT_NAMES } from '../../../utils/analytics';

function MultipleInput({ questionData, options }) {
  const profileData = useGlobalStore((state) => state.profileData);

  const validateField = () => (value) => {
    let error = '';
    const partnersEmailOption = questionData?.attributes.answer_options.data.find(
      (option) => option.attributes.identifier === QUIZ_IDENTIFIERS.partners_email_answer,
    );
    if (!value || value?.length === 0) {
      error = 'Field is required';
    }
    if (partnersEmailOption
       && profileData.data.attributes.joiner_email
       && value !== profileData.data.attributes.joiner_email
    ) {
      error = `${profileData.data.attributes.joiner_email} is the email of your current paired partner. To make changes, please email support@lovewick.com`;
    }
    return error;
  };

  const trackNameChange = ({ target: { value } }, option) => {
    if (option?.identifier === QUIZ_IDENTIFIERS.call_us_name_first_answer) {
      if (value === profileData?.data.attributes.name) return;
      Analytics.track(EVENT_NAMES.QUIZ_EDIT_OWN_NAME);
    }
    if (option?.identifier === QUIZ_IDENTIFIERS.call_us_name_second_answer) {
      if (value === profileData?.data.attributes.second_user_name) return;
      Analytics.track(EVENT_NAMES.QUIZ_EDIT_PARTNERS_NAME);
    }
  };

  return (
    <div className="flex flex-col gap-3 w-full">
      {
        options.map((option) => (
          <span key={option.id} onBlur={(e) => trackNameChange(e, option)}>
            <Field
              component={Input}
              inputClassName="border-black border-opacity-30"
              placeholder={option.name}
              name={option.id}
              key={option.id}
              validate={validateField()}
            />
          </span>
        ))
      }
    </div>
  );
}

export default MultipleInput;
