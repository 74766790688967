import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import LogoWhite from '../../assets/logo-white.svg';
import Card from '../common/Card';
import auth from '../../apis/auth';
import Button from '../common/Button';

function PaymentConfirmation() {
  const [searchParams] = useSearchParams();
  const [isSending, setIsSending] = useState(false);

  const handleClick = async () => {
    if (!searchParams.get('email')) return;
    setIsSending(true);
    await auth.requestMagicLink(searchParams.get('email'));
    setIsSending(false);
  };

  return (
    <div className="cyan-gradient-bg-image w-full h-full mx-auto">
      <div className="container mx-auto justify-center -translate-y-12 sm:justify-start sm:translate-y-0 sm:pt-28 items-center flex-col flex h-full">
        <img src={LogoWhite} alt="Love wick logo" className="w-28 h-28 mb-8" />
        <Card>
          <div className="text-center text-4xl">💌</div>
          <h1 className="text-center my-2.5">
            Thank you for your
            {' '}
            <br className=" hidden md:inline" />
            purchase!
          </h1>
          <p className="text-center">To access your dates, please check your email, and open the magic link on a desktop or tablet.</p>
          <Button disabled={isSending} onClick={handleClick} className="w-full mx-auto mt-7.5">Resend link</Button>
        </Card>
      </div>
    </div>
  );
}

export default PaymentConfirmation;
