import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import ErrorPage from './components/ErrorPage';
import TailoringQuiz from './components/TailoringQuiz';
import Root from './Root';
import PaymentConfirmation from './components/PaymentConfirmation';
import routes from './routes';
import RoomReady from './components/DateRoom/RoomReady';
import DateRoom from './components/DateRoom/Show';
import RequireAuth from './components/common/RequireAuth';
import TooManyAttempts from './components/DateRoom/TooManyAttempts';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path={routes.root}
      element={<Root />}
      errorElement={<ErrorPage />}
    >
      <Route index element={<Login />} />
      <Route path={routes.auth.login} element={<Login />} />
      <Route
        path={routes.paymentConfirmation.index}
        element={<PaymentConfirmation />}
      />
      <Route
        path={routes.dashboard.index}
        element={(
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        )}
      />
      <Route
        path={routes.room.ready}
        element={(
          <RequireAuth>
            <RoomReady />
          </RequireAuth>
        )}
      />
      <Route
        path={routes.room.show}
        element={(
          <DateRoom />
        )}
      />
      <Route
        path={routes.tailoringQuiz.index}
        element={(
          <RequireAuth>
            <TailoringQuiz />
          </RequireAuth>
        )}
      />
      <Route
        path={routes.tooManyAttempts.index}
        element={(
          <TooManyAttempts />
        )}
      />
    </Route>,
  ),
);

export default router;
