/* eslint-disable no-plusplus */
import { QUESTION_TYPES } from './constants';

/* eslint-disable import/prefer-default-export */
export const toAntdProgress = (progress) => progress * 100;

export const convertToOptions = (options) => options.map((option) => (
  { id: option.id, name: option.attributes.value, identifier: option.attributes.identifier }
));

const getAnswerOptionIndex = (
  answerOptionId,
  originalAnswerOptions,
) => originalAnswerOptions.findIndex((option) => answerOptionId === option.id);

export const buildQuestionAnswers = (questionData, values) => {
  let answers = [];
  const questionType = questionData.attributes.type;

  if (questionType === QUESTION_TYPES.RadioQuestion) {
    answers = Object.entries(values).map(([, val]) => ({
      answer_option_id: Number(val),
      value: '',
    }));
  } else if (questionType === QUESTION_TYPES.TextQuestion) {
    answers = Object.entries(values).map(([id, val]) => ({
      answer_option_id: Number(id),
      value: val,
    }));
  } else if (questionType === QUESTION_TYPES.ThreeScaleQuestion) {
    answers = [{
      answer_option_id: Number(Object.values(values)[0]),
      value: getAnswerOptionIndex(
        Object.values(values)[0],
        questionData.attributes.answer_options.data,
      ),
    }];
  } else if (questionType === QUESTION_TYPES.CheckboxQuestion) {
    answers = values[questionData.id].map((item) => ({
      answer_option_id: Number(item),
      value: '',
    }));
  }

  return answers;
};

export const validateRequired = (message = 'Field is required') => (value) => {
  let error = '';
  if (!value || value?.length === 0) {
    error = message;
  }
  return error;
};
