/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Modal, Rate, Tooltip } from 'antd';
import React from 'react';
import Clock from '../../../assets/clock.svg';
import Edit from '../../../assets/edit.png';
import Button from '../../common/Button';
import {
  isDateStarted, getDetailImage, splitLineBreaks, formatDateToMDY,
} from '../../../utils/general';
import VibeTags from '../../VibeTags';
import CustomStar from '../../CustomStar';
import useGlobalStore from '../../../stores/useGlobalStore';
import { DATE_STARTED_FROM } from './constants';

function DetailsModal({
  date, isModalOpen, isStartDateSubmitting,
  setIsModalOpen, onStartDateClick, onSwapDateClick, onReAccessDateClick,
  onNoSwapsLeftClick, onEditReviewClick,
}) {
  const profileData = useGlobalStore((state) => state.profileData);

  return (
    <Modal
      width="37rem"
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
      maskClosable={false}
      className="card-details"
      centered
    >
      <div className="w-[37.03rem]">
        <div className="w-full h-44">
          <img src={date && getDetailImage(date)} alt="" className="object-cover w-full h-full rounded-t-lg" />
        </div>
        <div className="py-5 px-3.5">
          <div className="border-b border-[#D9D9D9] mb-3.7">
            {/* TODO: make this a component */}
            {date?.attributes?.relationships_dream_date.data.attributes.reviewed_at && (
              <div className="bg-teal-light mb-3.7 rounded-lg p-5 flex items-center">
                <Rate
                  character={<CustomStar />}
                  className="card-details-rate"
                  disabled
                  value={
                    Number(date.attributes.relationships_dream_date.data.attributes.review_stars)
                  }
                  allowHalf
                />
                <p className="text-black text-opacity-80 ml-2.5 italic text-lg">
                  Reviewed
                  {' '}
                  {formatDateToMDY(
                    date.attributes.relationships_dream_date.data.attributes.reviewed_at,
                  )}
                </p>
                <Tooltip title="Edit review" className="">
                  <img
                    src={Edit}
                    alt="Edit"
                    className="cursor-pointer w-6 h-6 ml-auto"
                    onClick={() => onEditReviewClick(date)}
                  />
                </Tooltip>
              </div>
            )}
            <VibeTags date={date} />
            <h3 className=" text-3xl my-3.7 font-bold">{date?.attributes?.title}</h3>
            <div className="flex fold-bold mb-3.7 font-bold text-xl">
              <span className="mr-2.5">
                {date?.attributes?.activity_type}
                {' '}
                Date
              </span>
              <span className="flex items-center">
                <img src={Clock} alt="Clock" className="mr-2.5" />
                Min
                {' '}
                {date?.attributes?.estimated_duration}
              </span>
            </div>
          </div>
          <div className="border-b border-gray-300 mb-3.7">
            <h4 className="font-bold text-xl">What you need:</h4>
            <ul className="text-xl mb-3.7">
              {
                splitLineBreaks(date?.attributes?.what_you_need)?.map((text) => (
                  <li>
                    <span className="mx-2">•</span>
                    <span dangerouslySetInnerHTML={{ __html: text }} />
                  </li>
                ))
              }
            </ul>
          </div>
          <div className="mb-3.7">
            <h4 className="text-xl font-bold">Other recommendations:</h4>
            <ul className="text-xl">
              {
                splitLineBreaks(date?.attributes?.other_recommendations)?.map((text) => (
                  <li>
                    <span className="mx-2">•</span>
                    <span dangerouslySetInnerHTML={{ __html: text }} />
                  </li>
                ))
              }
            </ul>
          </div>
          <div className="flex flex-col">
            {
              isDateStarted(date)
                ? <Button className="mt-5" secondary onClick={() => onReAccessDateClick(date)}>Re-access date</Button>
                : (
                  <>
                    <Button
                      disabled={isStartDateSubmitting}
                      className="mt-5"
                      secondary
                      onClick={() => onStartDateClick(date, { startedFrom: DATE_STARTED_FROM.DETAILS_MODAL })}
                    >
                      Start date
                    </Button>
                    {
                      profileData?.data.attributes.total_dates_left_to_be_swapped_on_positive_likability > 0
                        ? <button className="mt-3.7 py-3.7 px-3.5 text-lg text-gray-800" type="button" onClick={() => onSwapDateClick(date)}>Swap date for a new one</button>
                        : <button className="mt-3.7 py-3.7 px-3.5 text-lg text-gray-800" type="button" onClick={onNoSwapsLeftClick}>No swaps left</button>
                    }
                  </>
                )
            }
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default DetailsModal;
