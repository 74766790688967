/* eslint-disable import/prefer-default-export */
import * as Yup from 'yup';

// TODO: add correct one for image
export const REVIEW_FORM_SCHEMA = Yup.object().shape({
  rating: Yup.number().required('Rating is required'),
  feedback: Yup.string().nullable(),
  image: Yup.mixed().nullable(),
});

export const DATE_STARTED_FROM = {
  CARD: 'card',
  DETAILS_MODAL: 'details-modal',
};
