import { Modal } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../common/Button';
import routes from '../../routes';
import useAuth from '../../hooks/useAuth';
import Analytics, { EVENT_NAMES } from '../../utils/analytics';

function LogoutModal({ isModalOpen, setIsModalOpen }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    setIsSubmitting(true);
    logout();
    setIsSubmitting(false);
    setIsModalOpen(false);
    navigate(`/${routes.auth.login}`);
    Analytics.track(EVENT_NAMES.ACCOUNT_CONFIRM_LOGOUT_ACCOUNT);
  };

  return (
    <Modal
      width="41rem"
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
      maskClosable={false}
      closable={false}
      className="profile-modal"
      centered
    >
      <div className="p-3.5">
        <h3 className="text-pink-500 text-lg font-bold">LOGGING IN AND OUT</h3>
        <h2 className="font-bold text-2xl my-2.5">A note before you log out</h2>
        <p className="text-2xl">
          There are no passwords for your date dashboard,
          so just request that a magic link be sent to your email on mydates.lovewick.com anytime!
        </p>
        <div className="flex justify-between mt-3.7">
          <Button
            disabled={isSubmitting}
            secondary
            onClick={handleLogout}
          >
            Log out
          </Button>
          <Button onClick={() => setIsModalOpen(false)}>Nevermind</Button>
        </div>
      </div>
    </Modal>
  );
}

export default LogoutModal;
