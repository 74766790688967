/* eslint-disable import/prefer-default-export */
import { v4 as uuidv4 } from 'uuid';

import { OVERLAY_LINEAR_GRADIENT } from '../../constants';

export const buildBgImageWithOverlay = (imageUrl) => `${OVERLAY_LINEAR_GRADIENT}, url(${imageUrl})`;

export const getRoomSlugFromUrl = () => {
  const match = window.location.pathname.match(/\/room\/([^/]+)$/);
  const roomSlug = match ? match[1] : null;
  return roomSlug;
};

export const convertSecondsToMS = (seconds) => Math.floor(seconds * 1000);

export const convertMSToSeconds = (ms) => ms / 1000;

export const buildRoomUrl = (rSlug) => `${window.location.origin}/room/${rSlug}`;

export const getVisitorId = () => {
  const savedUUID = localStorage.getItem('browser_id');
  if (savedUUID) return savedUUID;
  const visitorId = uuidv4();
  localStorage.setItem('browser_id', visitorId);

  return visitorId;
};

// eslint-disable-next-line max-len
export const doesVisitorIdExistInRoom = (visitorId, room) => room.attributes?.room_visitors?.data.some(
  (visitor) => visitor.attributes.identifier === visitorId,
);
