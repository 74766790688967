/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import { Input } from 'antd';

const AntdTextArea = Input.TextArea;

function TextArea(
  {
    field,
    form = {},
    className,
    textAreaClassName,
    ...otherProps
  },
) {
  return (
    <div className={classNames('flex flex-col items-center justify-center transition-all', {
      [className]: !!className,
    })}
    >
      <AntdTextArea
        rows={4}
        {...field}
        {...otherProps}
        className={classNames('!h-29 resize-none placeholder:text-xl placeholder:text-gray-700 rounded-lg border border-solid border-black p-5 focus:border-gray-950 focus:shadow-sm text-lg hover:border-black border-opacity-30 text-black', {
          [textAreaClassName]: !!textAreaClassName,
        })}
      />
      {form.touched && form.touched[field.name]
        && form.errors[field.name] && <div className="text-red-500 mt-2 self-start">{form.errors[field.name]}</div>}
    </div>
  );
}

export default TextArea;
