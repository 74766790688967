/* eslint-disable no-else-return */
import React from 'react';
import Button from '../../common/Button';
import Clock from '../../../assets/clock.svg';
import VibeTags from '../../VibeTags';
import { DATE_STARTED_FROM } from './constants';

function Card({
  tileImageUrl,
  date,
  isStartDateSubmitting,
  onDetailsClick,
  onStartDateClick,
}) {
  return (
    <div className="shadow-custom-card-light card w-[22rem] lg:w-[24.3rem] border border-black border-opacity-20 bg-white rounded-2.5xl">
      <img src={tileImageUrl} alt="Background" className="rounded-t-[1.20rem] w-[22rem] h-[11.87rem] lg:w-auto object-cover" />
      <div className="flex flex-col gap-y-3.7 p-3.5 items-center">
        <VibeTags date={date} />
        <h3 className="text-3xl font-bold">{date.attributes.title}</h3>
        <div className="flex">
          {date.attributes.requires_supplies && (
          <span className="mr-3.7">
            {date.attributes?.supplies_count}
            {' '}
            supplies
          </span>
          )}
          <span className="flex items-center">
            <img src={Clock} alt="Clock" className=" mr-2" />
            {date.attributes.estimated_duration}
          </span>
        </div>
        <div>
          <Button disabled={isStartDateSubmitting} secondary className="mr-4" onClick={() => onStartDateClick(date, { startedFrom: DATE_STARTED_FROM.CARD })}>Start date</Button>
          <Button
            onClick={() => onDetailsClick(date)}
          >
            Details
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Card;
