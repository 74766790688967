import { V1_API_BASE_URL } from '../constants';
import Analytics from '../utils/analytics';
import axios from './axios';

const requestMagicLink = (email) => axios.post(`${V1_API_BASE_URL}/login`, {
  user: {
    email,
    send_magic_link: true,
  },
});

const getProfile = async () => {
  const resp = await axios.get(`${V1_API_BASE_URL}/user`, {
    params: {
      timestamp: new Date().getTime(),
    },
  });
  Analytics.setUserId(resp?.data?.data?.attributes?.id);
  return resp;
};

const deleteAccount = () => axios.delete(`${V1_API_BASE_URL}/user`);

export default {
  requestMagicLink,
  getProfile,
  deleteAccount,
};
