import { Modal } from 'antd';
import React from 'react';
import { Field, Form, Formik } from 'formik';
import Input from '../../common/Input';
import Button from '../../common/Button';
import { SEND_PARTNER_INVITE_SCHEMA } from './constants';
import useGlobalStore from '../../../stores/useGlobalStore';

function SendPartnerInvite({ isModalOpen, setIsModalOpen, onSendInvite }) {
  const profileData = useGlobalStore((state) => state.profileData);

  const validateField = () => (value) => {
    if (profileData.data.attributes.joiner_email
      && value !== profileData.data.attributes.joiner_email
    ) {
      return `${profileData.data.attributes.joiner_email} is the email of your current paired partner. To make changes, please email support@lovewick.com`;
    }
    return '';
  };

  return (
    <Modal
      width="41rem"
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
      closable={false}
      centered
      className="send-partner-invite-modal"
    >
      <div className="py-13 px-10">
        <h1 className="text-center">
          Send (or re-send)
          {' '}
          {profileData?.data.attributes.joiner_quiz_name}
          &apos;s quiz invite
        </h1>
        <Formik
          initialValues={{ email: profileData?.data.attributes.joiner_email || '' }}
          validationSchema={SEND_PARTNER_INVITE_SCHEMA}
          onSubmit={onSendInvite}
        >
          {({ isSubmitting }) => (
            <Form className="flex flex-col items-center">
              <Field validate={validateField()} className="w-[96%] my-7.5" type="email" name="email" component={Input} placeholder={`${profileData?.data.attributes.joiner_quiz_name || 'Partner'}'s email`} />
              <Button secondary type="submit" disabled={isSubmitting} className="w-full mx-auto">Send invite</Button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default SendPartnerInvite;
