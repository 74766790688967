import { Modal } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../common/Button';
import AuthApi from '../../apis/auth';
import useAuth from '../../hooks/useAuth';
import routes from '../../routes';
import Analytics, { EVENT_NAMES } from '../../utils/analytics';

function DeleteAccountModal({ isModalOpen, setIsModalOpen }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleAccountDelete = async () => {
    setIsSubmitting(true);
    await AuthApi.deleteAccount();
    setIsSubmitting(false);
    setIsModalOpen(false);
    logout();
    navigate(`/${routes.auth.login}`);
    Analytics.track(EVENT_NAMES.ACCOUNT_CONFIRM_DELETE_ACCOUNT);
  };

  return (
    <Modal
      width="41rem"
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
      maskClosable={false}
      closable={false}
      className="profile-modal"
      centered
    >
      <div className="p-3.5">
        <h3 className="text-pink-500 text-lg font-bold">ACCOUNT DELETION</h3>
        <h2 className="font-bold text-2xl my-2.5">What to consider before deleting your account</h2>
        <p className="text-2xl">
          When you delete your account, all data on your dashboard will be scrubbed,
          including unused dates, and you will no longer be able to log in.
          You’re welcome to sign up again in the future as a new user.
          If instead you’d just like to remove a partner, please contact support@lovewick.com.
        </p>
        <div className="flex justify-between mt-3.7">
          <Button
            disabled={isSubmitting}
            secondary
            onClick={handleAccountDelete}
          >
            Delete my account
          </Button>
          <Button onClick={() => setIsModalOpen(false)}>Nevermind</Button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteAccountModal;
