import { V1_API_BASE_URL } from '../constants';
import axios from './axios';

const getQuiz = () => axios.post(`${V1_API_BASE_URL}/quizzes`, { quiz: { created_for: 'DateQuestionnaire' } });
const submitAnswer = ({
  quizId, questionId, answers, notQuiz,
}) => axios.post(`${V1_API_BASE_URL}/quizzes/${quizId}/questions/${questionId}/answers/create_multiple`, {
  answers,
  ...(notQuiz && { change_current_question: false }),
});
const sendPartnerInvite = ({ quizId, sendInvite = true, joinerEmail }) => axios.patch(`${V1_API_BASE_URL}/quizzes/${quizId}`, {
  quiz: {
    send_partner_invite_for_date_questionnaire: sendInvite,
    ...(joinerEmail && { joiner_email: joinerEmail }),
  },
});

export default {
  getQuiz,
  submitAnswer,
  sendPartnerInvite,
};
