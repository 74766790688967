import { V1_API_BASE_URL } from '../constants';
import axios from './axios';

const getRoom = (roomSlug) => axios.get(`${V1_API_BASE_URL}/rooms/${roomSlug}`);
const createRoom = ({ relationshipsDreamDateId }) => axios.post(`${V1_API_BASE_URL}/rooms`, {
  room: {
    relationships_dream_date_id: Number(relationshipsDreamDateId),
    video_playing_status: 'yet_to_start',
    current_video_seek: '0',
  },
});
const syncEvents = ({ roomSlug, currentVideoSeekMs, videoPlayingStatus }) => axios.patch(`${V1_API_BASE_URL}/rooms/${roomSlug}`, {
  room: {
    ...(videoPlayingStatus !== undefined && { video_playing_status: videoPlayingStatus }),
    ...(currentVideoSeekMs !== undefined && { current_video_seek: currentVideoSeekMs }),
  },
});
const addJoiner = ({
  roomSlug, userId, userName, joiningTime, existingJoiners,
}) => {
  axios.patch(`${V1_API_BASE_URL}/rooms/${roomSlug}`, {
    room: {
      joiners: [
        ...existingJoiners,
        { user_id: userId, joining_time: joiningTime, user_name: userName },
      ],
    },
  });
};
const addVisitor = ({
  roomSlug, visitorId,
}) => {
  axios.patch(`${V1_API_BASE_URL}/rooms/${roomSlug}`, {
    room: {
      room_visitors_attributes: [
        { identifier: visitorId },
      ],
    },
  });
};
const removeJoiner = ({ roomSlug, requestBody }) => {
  fetch(`${V1_API_BASE_URL}/rooms/${roomSlug}?date=true`, {
    method: 'PATCH',
    importance: 'high',
    headers: {
      'Content-Type': 'application/json',
    },
    keepalive: true,
    body: JSON.stringify(requestBody),
  });
};

export default {
  getRoom,
  createRoom,
  syncEvents,
  addJoiner,
  removeJoiner,
  addVisitor,
};
