/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Dropdown } from 'antd';
import classNames from 'classnames';
import LogoWhite from '../../assets/logo-white.svg';
import Button from '../common/Button';
import Cross from '../../assets/cross.svg';
import useGlobalStore from '../../stores/useGlobalStore';
import { getStripeCheckoutLink } from './utils';
import ContactSupportModal from './ContactSupportModal';
import DeleteAccountModal from './DeleteAccountModal';
import LogoutModal from './LogoutModal';
import { JOINER_DEFAULT_NAME, STRIPE_CHECKOUT_LINKS } from './constants';
import { buildProfileUserName } from '../../utils/general';
import NotEnoughDatesLeftModal from './NotEnoughDatesLeftModal';
import Analytics, { EVENT_NAMES, PROPERTY_NAMES } from '../../utils/analytics';

function Layout({ children, wrapperClassNames }) {
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const [isContactSupportModalOpen, setIsContactSupportModalOpen] = useState(false);
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isUnableToBuyMoreDatesOpen, setIsUnableToBuyMoreDatesOpen] = useState(false);
  const { joinerName, loggedInUserName, purchaserName } = useGlobalStore((state) => ({
    joinerName: state.profileData?.data.attributes.joiner_quiz_name,
    purchaserName: state.profileData?.data.attributes.purchaser_quiz_name,
    loggedInUserName: state.profileData?.data.attributes.is_purchaser
      ? state.profileData?.data.attributes.purchaser_quiz_name
      : state.profileData?.data.attributes.joiner_quiz_name,
  }));
  const profileData = useGlobalStore((state) => state.profileData);

  const dropdownItems = [
    { label: 'Contact support', key: 0 },
    { label: 'Delete account', key: 1 },
    { label: 'Log out', key: 2 },
  ];

  const handleMenuClick = (e) => {
    setIsProfileDropdownOpen(false);
    if (e.key === '0') {
      setIsContactSupportModalOpen(true);
    } else if (e.key === '1') {
      setIsDeleteAccountModalOpen(true);
      Analytics.track(EVENT_NAMES.ACCOUNT_CLICK_TO_DELETE_ACCOUNT);
    } else if (e.key === '2') {
      setIsLogoutModalOpen(true);
      Analytics.track(EVENT_NAMES.ACCOUNT_CLICK_TO_LOGOUT_ACCOUNT);
    }
  };

  const handleProfileIconClick = () => {
    Analytics.track(EVENT_NAMES.DASHBOARD_CLICK_INTO_ACCOUNTS);
    setIsProfileDropdownOpen((prev) => !prev);
  };

  const handleBuyMoreClick = () => {
    Analytics.setSendBeaconAsTransport();
    Analytics.track(
      EVENT_NAMES.DASHBOARD_CLICK_BUY_MORE_DATES,
      { [PROPERTY_NAMES.BUY_FROM]: 'top cta' },
    );
    if (profileData?.data?.attributes?.total_dates_left_to_be_alloted_on_positive_likability < 3) {
      setIsUnableToBuyMoreDatesOpen(true);
    } else {
      window.location.href = getStripeCheckoutLink(STRIPE_CHECKOUT_LINKS.PACK_OF_THREE);
    }
  };

  const buildDashboardTitle = () => {
    if (profileData?.data.attributes.use_my_quiz_only
      && profileData?.data.attributes.joiner_quiz_name === JOINER_DEFAULT_NAME
    ) {
      return `${purchaserName}'s Date Dashboard`;
    }
    return `${purchaserName} & ${joinerName}'s Date Dashboard`;
  };

  return (
    <div className="">
      <div className="header bg-custom-gray py-5 px-3.5 flex text-white">
        <img src={LogoWhite} alt="" className="w-17 mr-auto" />
        <div className="flex gap-5 items-center relative">
          <Button className="py-2.5 px-3.5" onClick={handleBuyMoreClick}>Buy more dates</Button>
          <Dropdown
            open={isProfileDropdownOpen}
            menu={{ items: dropdownItems, onClick: handleMenuClick }}
            trigger={['click']}
          >
            <div className="relative select-none flex justify-center items-center w-11 h-11 cursor-pointer">
              <span
                onClick={handleProfileIconClick}
                className={classNames('flex justify-center items-center w-11 h-11 rounded-full border-white border', {
                  hidden: isProfileDropdownOpen,
                  block: !isProfileDropdownOpen,
                })}
              >
                {buildProfileUserName(loggedInUserName)}
              </span>
              <span
                className={classNames('bg-white absolute w-11 h-11 rounded-full flex justify-center items-center', {
                  hidden: !isProfileDropdownOpen,
                  block: isProfileDropdownOpen,
                })}
                onClick={() => setIsProfileDropdownOpen(false)}
              >
                <img src={Cross} alt="Cross icon" />
              </span>
            </div>
          </Dropdown>
        </div>
      </div>
      <div className="bg-gray-light py-10.5 mx-auto">
        <div className={classNames(wrapperClassNames, 'px-5 md:px-24 container mx-auto  ')}>
          <h3 className="text-4.5xl font-bold">
            {buildDashboardTitle()}
          </h3>
          {children}
        </div>
      </div>
      <ContactSupportModal
        isModalOpen={isContactSupportModalOpen}
        setIsModalOpen={setIsContactSupportModalOpen}
      />
      <DeleteAccountModal
        isModalOpen={isDeleteAccountModalOpen}
        setIsModalOpen={setIsDeleteAccountModalOpen}
      />
      <LogoutModal
        isModalOpen={isLogoutModalOpen}
        setIsModalOpen={setIsLogoutModalOpen}
      />
      <NotEnoughDatesLeftModal
        isModalOpen={isUnableToBuyMoreDatesOpen}
        setIsModalOpen={setIsUnableToBuyMoreDatesOpen}
      />
    </div>
  );
}

export default Layout;
