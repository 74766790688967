import React from 'react';
import FadeLoader from 'react-spinners/FadeLoader';

function Spinner({ loading, children, className }) {
  return (
    <>
      <FadeLoader
        color="#1F2A2F"
        size={150}
        aria-label="Loading Spinner"
        loading={loading}
        className={className}
      />
      {!loading && children}
    </>
  );
}

export default Spinner;
