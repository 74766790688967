import { Modal } from 'antd';
import React from 'react';
import Button from '../common/Button';

function NotEnoughDatesLeftModal({
  isModalOpen, setIsModalOpen,
}) {
  return (
    <Modal
      width="41rem"
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
      maskClosable={false}
      closable={false}
      className="card-details"
      centered
    >
      <div className="p-3.5">
        <h3 className="text-pink-500 text-lg font-bold">
          OOPS! UNABLE TO BUY MORE DATES
        </h3>
        <h2 className="font-bold text-2xl my-2.5">There aren’t enough dates for you right now </h2>
        <p className="text-2xl">
          Based on your quiz results, there are not currently enough
          dates that match your preferences.
          If you’re open to dates outside your reported preferences,
          email us at support@lovewick.com.
          Otherwise, check again once our date catalog grows. Thank you!
        </p>
        <div className="flex justify-between mt-3.7">
          <Button secondary onClick={() => setIsModalOpen(false)}>Close</Button>
        </div>
      </div>
    </Modal>
  );
}

export default NotEnoughDatesLeftModal;
