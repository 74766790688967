/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import { toast } from 'react-toastify';
import { DATE_STATES } from '../constants';

/* eslint-disable import/prefer-default-export */
export const isMobile = () => window.matchMedia('(max-width: 500px)').matches;

export const preloadImages = (imageUrls) => {
  imageUrls.forEach((imgUrl) => {
    const img = new Image();
    img.src = imgUrl;
  });
};

export const getCurrentDate = () => {
  const currentDate = new Date();

  const day = currentDate.getDate().toString().padStart(2, '0');
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const year = currentDate.getFullYear();

  // TODO: Why this d-m-y
  return `${day}-${month}-${year}`;
};

export const getCurrentDateTime = () => {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');

  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;

  return formattedDateTime;
};

export const formatDateToMDY = (inputDate) => {
  const dateObject = new Date(inputDate);

  const month = dateObject.getMonth() + 1;
  const day = dateObject.getDate();
  const year = dateObject.getFullYear();

  return `${month}/${day}/${year}`;
};

function isPrimitive(obj) {
  return (obj !== Object(obj));
}

export const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) { return true; }

  if (isPrimitive(obj1) && isPrimitive(obj2)) { return obj1 === obj2; }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }

  for (const key in obj1) {
    if (!(key in obj2)) return false;
    if (!deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
};

// eslint-disable-next-line max-len
export const isDateStarted = (date) => !!date?.attributes?.relationships_dream_date.data.attributes.started_at;
// eslint-disable-next-line max-len
export const isReviewed = (date) => !!date?.attributes?.relationships_dream_date.data.attributes.reviewed_at;

export const getStateOfDate = (date) => {
  if (isDateStarted(date) && !isReviewed(date)) {
    return DATE_STATES.ROOM_JOINED;
  }
  if (isDateStarted(date) && isReviewed(date)) {
    return DATE_STATES.REVIEW_SUBMITTED;
  }

  return DATE_STATES.DEFAULT;
};

export const getDetailImage = (date) => {
  const state = getStateOfDate(date);
  if (state === DATE_STATES.ROOM_JOINED) {
    return date?.attributes.review_pending_detail_image_url;
  }
  if (state === DATE_STATES.REVIEW_SUBMITTED) {
    return date?.attributes?.relationships_dream_date.data.attributes.review_image_url
      || date?.attributes.placeholder_detail_image_url;
  }

  return date?.attributes.mystery_detail_image_url;
};

export const splitLineBreaks = (date) => date?.split(/\r?\n/)?.filter((str) => str.length);

export const capitalize = (str) => str.toUpperCase();

export const buildProfileUserName = (userName) => {
  if (!userName) return '';

  const [first, second] = userName.split(' ');
  return capitalize(first[0]) + capitalize(second ? second[0] : '');
};

export const toastSuccess = (message) => {
  toast.success(message, {
    position: 'bottom-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'light',
  });
};

export const isEmpty = (obj) => Object.keys(obj).length === 0;

export const once = (fn) => {
  let executed = false;
  return (...args) => {
    if (!executed) {
      executed = true;
      fn(args);
    }
  };
};
