import * as amplitude from '@amplitude/analytics-browser';

const EVENT_NAMES = {
  QUIZ_VIEW_QUIZ_PAGE: 'quiz: view quiz page',
  QUIZ_SUBMIT_QUIZ_ANSWER: 'quiz: submit quiz answer',
  QUIZ_SEND_PARTNER_INVITE: 'quiz: send partner invite',
  QUIZ_SKIP_PARTNER_INVITE: 'quiz: skip partner invite',
  QUIZ_EDIT_OWN_NAME: 'quiz: edit own name',
  QUIZ_EDIT_PARTNERS_NAME: "quiz: edit partner's name",
  DASHBOARD_VIEW_WAITING_STATE_PAGE: 'dashboard: view waiting state page',
  DASHBOARD_VIEW_DASHBOARD_MAIN_PAGE: 'dashboard: view dashboard main page',
  DASHBOARD_CLICK_USEMY_RESULTS_ONLY: 'dashboard: click use my results only',
  DASHBOARD_CLICK_SEND_PARTNER_INVITE: 'dashboard: click send partner invite',
  DASHBOARD_CLICK_INTO_DATE_DETAILS: 'dashboard: click into date details',
  DASHBOARD_CLICK_START_DATE: 'dashboard: click start date',
  DASHBOARD_CLICK_TO_SWAP_DATE: 'dashboard: click to swap date',
  DASHBOARD_CONFIRM_SWAP_DATE: 'dashboard: confirm swap date',
  DASHBOARD_DECLINE_SWAP_DATE: 'dashboard: decline swap date',
  WAITING_ROOM_VIEW_WAITROOM: 'date waitroom: view date waitroom',
  WAITING_CLICK_COPY_ROOM_LINK: 'date waitroom: click to copy room link',
  WAITING_CLICK_JOIN_ROOM: 'date waitroom: click to join room',
  ROOM_VIEW_VIDEO_DATE_ROOM: 'date room: view video date room',
  DASHBOARD_CLICK_TO_LEAVE_A_REVIEW: 'dashboard: click to leave a review',
  DASHBOARD_CLICK_TO_SUBMIT_A_REVIEW: 'review: click to submit review',
  DASHBOARD_CLICK_BUY_MORE_DATES: 'dashboard: click buy more dates button',
  DASHBOARD_CLICK_INTO_ACCOUNTS: 'dashboard: click into account',
  ACCOUNT_CLICK_TO_DELETE_ACCOUNT: 'account: click to delete account',
  ACCOUNT_CONFIRM_DELETE_ACCOUNT: 'account: confirm delete account',
  ACCOUNT_CLICK_TO_LOGOUT_ACCOUNT: 'account: click to log out',
  ACCOUNT_CONFIRM_LOGOUT_ACCOUNT: 'account: confirm log out',
};
const PROPERTY_NAMES = {
  QUIZ_QUESTION_IDENTIFIER: 'quiz question identifier',
  QUIZ_ANSWER_IDENTIFIER: 'quiz answer identifier',
  DATE_IDENTIFIER: 'date identifier',
  TAGS: 'tags',
  CATEGORY: 'category',
  NUMBER_OF_SUPPLIES: 'number of supplies',
  DURATION_IN_MINUTES: 'duration in minutes',
  BUY_FROM: 'buy from',
};

const Analytics = {
  userId: undefined,
  initialize: () => {
    amplitude.init(
      process.env.REACT_APP_AMPLITUDE_API_KEY,
      undefined,
      {
        defaultTracking: {
          pageViews: false,
          sessions: false,
          formInteractions: false,
          fileDownloads: false,
          attribution: {
            disabled: true,
          },
        },
      },
    );
  },
  track: (...args) => amplitude.track(...args),
  setUserId: (userId) => {
    if (Analytics.userId === undefined) {
      amplitude.setUserId(userId);
      Analytics.userId = userId;
    }
  },
  buildDateProperties: (date) => ({
    [PROPERTY_NAMES.DATE_IDENTIFIER]: date.attributes.identifier,
    [PROPERTY_NAMES.TAGS]: date.attributes.tags.data.map((tag) => tag.attributes.name),
    [PROPERTY_NAMES.CATEGORY]: date.attributes.date_category,
    [PROPERTY_NAMES.NUMBER_OF_SUPPLIES]: date.attributes.supplies_count,
    [PROPERTY_NAMES.DURATION_IN_MINUTES]: date.attributes.estimated_duration,
  }),
  buildViewQuizPageProperties: (questionIdentifier) => ({
    [PROPERTY_NAMES.QUIZ_QUESTION_IDENTIFIER]: questionIdentifier,
  }),
  buildQuizSubmitAnswerProperties: (questionData, answers) => {
    const answerIdentifiers = questionData.attributes.answer_options.data
      .filter((option) => answers.find((answer) => answer.answer_option_id === Number(option.id)))
      .map((option) => option.attributes.identifier);

    return {
      [PROPERTY_NAMES.QUIZ_QUESTION_IDENTIFIER]: questionData.attributes.identifier,
      [PROPERTY_NAMES.QUIZ_ANSWER_IDENTIFIER]: answerIdentifiers,
    };
  },
  setSendBeaconAsTransport: () => amplitude.setTransport('beacon'),
  reset: () => amplitude.reset(),
};

export default Analytics;
export { EVENT_NAMES, PROPERTY_NAMES };
