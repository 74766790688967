import classNames from 'classnames';
import { useFormikContext } from 'formik';
import React from 'react';

function ValidationError({ fieldName, className }) {
  const form = useFormikContext();
  return (form.errors[fieldName] && <div className={classNames('text-red-500 mt-2 self-start', className)}>{form.errors[fieldName]}</div>);
}

export default ValidationError;
