/* eslint-disable import/prefer-default-export */
import routes from '../routes';

export const MOBILE_NOT_SUPPORTED_IGNORED_ROUTES = [routes.paymentConfirmation.index];

export const CONTACT_US_PAGE_URL = 'https://lovewick.com/contact-us';

export const OVERLAY_LINEAR_GRADIENT = 'linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))';

export const V1_API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1`;

/* eslint-disable import/prefer-default-export */
export const DATE_STATES = {
  DEFAULT: 'DEFAULT',
  ROOM_JOINED: 'ROOM_JOINED',
  REVIEW_SUBMITTED: 'REVIEW_SUBMITTED',
};

export const LOCALSTORAGE_KEYS = {
  AUTH_TOKEN: 'auth_token',
  USER_EMAIL: 'user_email',
};

export const QUIZ_IDENTIFIERS = {
  send_partner_invite: 'send_partner_invite',
  use_how: 'use_how',
  call_us_name_first_answer: 'call_us_name_first_answer',
  call_us_name_second_answer: 'call_us_name_second_answer',
  call_me_name_answer: 'call_me_name_answer',
  partners_email_answer: 'partners_email_answer',
  call_us_names: 'call_us_names',
};
