/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
import One from '../../../assets/card-bg/1.png';
import Two from '../../../assets/card-bg/2.png';
import Three from '../../../assets/card-bg/3.png';
import Four from '../../../assets/card-bg/4.png';
import Five from '../../../assets/card-bg/5.png';
import Six from '../../../assets/card-bg/6.png';
import { QUIZ_IDENTIFIERS } from '../../../constants';

export const getCardColor = (id) => {
  const cardBgImages = [
    { bgImage: One, color1: '#F0FCC4', color2: '#FBE9F0' },
    { bgImage: Two, color1: '#FBE9F0', color2: '#FCF4CC' },
    { bgImage: Three, color1: '#F4E7F6', color2: '#FCF4CC' },
    { bgImage: Four, color1: '#DCF8FC', color2: '#DDF8FC' },
    { bgImage: Five, color1: '#FCF4CC', color2: '#FCF4CC' },
    { bgImage: Six, color1: '#F4E7F6', color2: '#FBE9F0' },
  ];
  const index = (id - 1) % cardBgImages.length;
  const { color1, color2, bgImage } = cardBgImages[index];

  return { bgImage, color1, color2 };
};

export const getCardDetailImageUrls = (datesData) => datesData.flatMap((date) => [
  date.attributes.placeholder_detail_image_url,
  date.attributes.review_pending_detail_image_url,
  date.attributes.mystery_detail_image_url,
  date.attributes?.relationships_dream_date.data.attributes.review_image_url,
]);

export function removeBase64Prefix(base64String) {
  if (base64String.indexOf(',') !== -1) {
    const [, base64WithoutPrefix] = base64String.split(',');

    return base64WithoutPrefix;
  }

  return base64String;
}

export function extractObjectByIdentifier(data, identifier) {
  if (identifier === QUIZ_IDENTIFIERS.use_how) {
    return data.attributes.first_question.data;
  } if (identifier === QUIZ_IDENTIFIERS.send_partner_invite) {
    return data.attributes.current_question.data;
  }
  return null;
}
