/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo-white.svg';
import Button from '../common/Button';
import ExpandIcon from './ExpandIcon';
import { buildRoomUrl } from './utils';
import RoomAPI from '../../apis/room';
import useGlobalStore from '../../stores/useGlobalStore';
import BeforeJoiningModal from './BeforeJoiningModal';
import SuppliesModal from './SuppliesModal';
import Spinner from '../common/Spinner';
import Analytics, { EVENT_NAMES } from '../../utils/analytics';
import { once } from '../../utils/general';

function RoomReady() {
  const [roomData, setRoomData] = useState();
  const [roomURL, setRoomURL] = useState('');
  const roomSlug = useGlobalStore((state) => state.roomSlug);
  const beforeJoiningModalShown = useGlobalStore((state) => state.beforeJoiningModalShown);
  const [isSuppliesModalOpen, setIsSuppliesModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [
    isBeforeJoiningModalOpen,
    setIsBeforeJoiningModalOpen,
  ] = useState(!beforeJoiningModalShown);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoom = async () => {
      const { data } = await RoomAPI.getRoom(roomSlug);
      setRoomData(data.data);
      setRoomURL(buildRoomUrl(data.data.attributes.slug));
    };
    fetchRoom();
  }, []);

  const sendAnalytics = useCallback(once((room) => {
    Analytics.track(
      EVENT_NAMES.WAITING_ROOM_VIEW_WAITROOM,
      Analytics.buildDateProperties(room[0].attributes.dream_date.data),
    );
  }), []);

  useEffect(() => {
    if (roomData) {
      sendAnalytics(roomData);
    }
  }, [roomData]);

  useEffect(() => {
    if (roomData?.attributes?.room_background_image_url === null) return;
    const imageLoader = new Image();
    imageLoader.src = `${roomData?.attributes?.room_background_image_url}}`;
    imageLoader.onload = () => {
      setIsLoading(false);
    };
  }, [roomData?.attributes?.room_background_image_url]);

  const handleCopylink = async () => {
    await navigator.clipboard.writeText(roomURL);
    Analytics.track(
      EVENT_NAMES.WAITING_CLICK_COPY_ROOM_LINK,
      Analytics.buildDateProperties(roomData.attributes.dream_date.data),
    );
  };

  const handleJoinRoom = () => {
    // TODO: create a buildRoute func
    navigate(`/room/${roomSlug}`);
    Analytics.track(
      EVENT_NAMES.WAITING_CLICK_JOIN_ROOM,
      Analytics.buildDateProperties(roomData.attributes.dream_date.data),
    );
  };

  if (isLoading) return <Spinner className="!absolute !top-1/2 !left-1/2" />;

  return (
    <div className="h-full bg-cover" style={{ backgroundImage: `url(${roomData?.attributes?.background_image_with_overlay_url})` }}>
      <div className="flex flex-col justify-center items-center text-white mx-auto h-full max-w-lg md:max-w-3xl lg:max-w-4xl xl:max-w-5xl lg:pt-18">
        <img src={Logo} alt="Love wick logo" />
        {/* TODO: */}
        <h3 className="mt-7.5 font-bold text-3xl">{roomData?.attributes?.dream_date?.data?.attributes?.title}</h3>
        <h2 className="mt-5 text-5xl font-bold text-center">Your virtual date room is ready!</h2>
        <div className="w-full bg-white mt-7.5 py-5 px-3.5 flex items-center rounded-lg border border-opacity-10">
          <span className="overflow-hidden whitespace-nowrap overflow-ellipsis mr-5 lg:mr-auto text-black text-xl max-w-[8rem] md:max-w-[24rem] lg:max-w-[32rem] xl:max-w-[40rem]">
            {roomURL}
          </span>
          <Button className="mr-4.3" onClick={handleCopylink}> Copy link </Button>
          <Button secondary onClick={handleJoinRoom}> Join room </Button>
        </div>
        <div className="mt-16 h-44 flex justify-center">
          <Collapse
            bordered={false}
            expandIconPosition="end"
            expandIcon={ExpandIcon}
            className="bg-white md:w-[43.75rem] p-3.5 text-xl text-black font-bold rounded-2.5xl h-fit"
            items={[
              {
                key: '1',
                label: '🚦Review pre-date checklist',
                children: (
                  <ul className="text-xl font-normal mt-5">
                    <li className="flex mt-2">
                      <span className="mx-2">•</span>
                      <span>
                        Check that you & your partner both have the necessary
                        {' '}
                        <a
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            setIsSuppliesModalOpen(true);
                          }}
                        >
                          supplies
                        </a>
                      </span>
                    </li>
                    <li className="flex mt-2">
                      <span className="mx-2">•</span>
                      <span>
                        {' '}
                        Put in headphones & jump on a video call together from your computers
                        (Google Meet, Discord, WhatsApp, FaceTime, etc.)
                      </span>
                    </li>
                    <li className="flex mt-2">
                      <span className="mx-2">•</span>
                      <span>
                        {' '}
                        Adjust window sizes on your computer so you can
                        see both this screen and your video call
                      </span>
                    </li>
                  </ul>
                ),
              },
            ]}
          />
        </div>
      </div>
      <BeforeJoiningModal
        isModalOpen={isBeforeJoiningModalOpen}
        setIsModalOpen={setIsBeforeJoiningModalOpen}
      />
      <SuppliesModal
        date={roomData?.attributes.dream_date?.data}
        isModalOpen={isSuppliesModalOpen}
        setIsModalOpen={setIsSuppliesModalOpen}
      />
    </div>
  );
}

export default RoomReady;
