import React from 'react';
import Card from '../common/Card';
import Button from '../common/Button';
import { CONTACT_US_PAGE_URL } from '../../constants';
import LogoWhite from '../../assets/logo-white.svg';

function TooManyAttempts() {
  const handleClick = () => {
    window.location.href = CONTACT_US_PAGE_URL;
  };

  return (
    <div className="cyan-gradient-bg-image w-full h-full mx-auto">
      <div className="container mx-auto justify-center -translate-y-12 sm:justify-start sm:translate-y-0 sm:pt-28 items-center flex-col flex h-full">
        <img src={LogoWhite} alt="Love wick logo" className="w-28 h-28 mb-7" />
        <Card>
          <span className="text-4xl text-center">😵‍💫</span>
          <h1 className="text-center my-2.5">
            Too many attempts
          </h1>
          <p className="text-center mb-4">
            It looks like too many people have tried to access this date URL,
            and you are now locked out.
          </p>
          <p className="text-center mb-4">
            If you think this is an error, please contact us so we can help:
          </p>
          <p className="text-center">
            support@lovewick.com
          </p>
          <Button className="mt-7.5" secondary onClick={handleClick}>Go to Contact Us page</Button>
        </Card>
      </div>
    </div>
  );
}

export default TooManyAttempts;
