import React from 'react';
import ReviewSubmittedCard from './ReviewSubmittedCard';
import MysteryCard from './MysteryCard';
import RoomJoinedCard from './RoomJoinedCard';
import { DATE_STATES } from '../../../constants';
import { getStateOfDate } from '../../../utils/general';

function DateCardRenderer({
  date,
  isStartDateSubmitting,
  onDetailsClick,
  onStartDateClick,
  onReviewClick,
}) {
  const dateState = getStateOfDate(date);

  if (dateState === DATE_STATES.DEFAULT) {
    return (
      <MysteryCard
        tileImageUrl={date.attributes.mystery_tile_image_url}
        key={date.id}
        date={date}
        isStartDateSubmitting={isStartDateSubmitting}
        onDetailsClick={onDetailsClick}
        onStartDateClick={onStartDateClick}
      />
    );
  }
  if (dateState === DATE_STATES.ROOM_JOINED) {
    return (
      <RoomJoinedCard
        tileImageUrl={date.attributes.review_pending_tile_image_url}
        key={date.id}
        date={date}
        onReviewClick={onReviewClick}
        onDetailsClick={onDetailsClick}
      />
    );
  }
  if (dateState === DATE_STATES.REVIEW_SUBMITTED) {
    return (
      <ReviewSubmittedCard
        tileImageUrl={
          date?.attributes?.relationships_dream_date.data.attributes.review_image_url
          || date.attributes.placeholder_tile_image_url
        }
        key={date.id}
        date={date}
        onDetailsClick={onDetailsClick}
      />
    );
  }
}

export default DateCardRenderer;
