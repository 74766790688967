/* eslint-disable import/prefer-default-export */
import One from '../../assets/how-it-works/1.png';
import Two from '../../assets/how-it-works/2.png';
import Three from '../../assets/how-it-works/3.png';

export const CAROUSEL_ITEMS = [
  {
    id: 1,
    image: One,
    heading: 'Your partner takes the quiz',
    description: 'You’ve opted in to blend your quiz results. You’ll be able to see your dates once they also take their quiz!',
  },
  {
    id: 2,
    image: Two,
    heading: 'Explore your selection of mystery dates',
    description: 'Each riddle-style date card includes just enough detail to come feeling both prepared and curious.',
  },
  {
    id: 3,
    image: Three,
    heading: 'Pick a date, gather any supplies, and make time',
    description: 'Once you’ve chosen a date and have supplies, “start” the date and join a virtual date room with your partner. Press play on the video, and enjoy at your own pace!',
  },
];

export const SUPPORT_EMAIL = 'support@lovewick.com';

export const STRIPE_CHECKOUT_LINKS = {
  PACK_OF_THREE: {
    development: 'https://buy.stripe.com/test_6oE2as3k27Av3CgbIM',
    production: 'https://buy.stripe.com/7sI4jQ30A84f5gs4gk',
  },
};

export const JOINER_DEFAULT_NAME = 'Partner';
